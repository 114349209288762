import {HttpContext, HttpErrorResponse, HttpRequest} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Actions, concatLatestFrom, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {forkJoin, from, merge, Observable, of} from 'rxjs';
import {catchError, finalize, map, switchMap} from 'rxjs/operators';
import {ClassesService} from '../../../../../../../src/app/api-client/classes.service';
import {AppStateService} from '../../../../../../../src/app/app.service';
import {ClasslinkService} from '../../../../../../../src/app/core/classlink.service';
import {CachePriority} from '../../../../../../../src/app/core/enums';
import {Gg4lAuthService} from '../../../../../../../src/app/core/gg4l-auth.service';
import {MsalService} from '../../../../../../../src/app/core/msal.service';
import {UserType} from '../../../../../../../src/app/enums';
import {SsoProvider} from '../../../../../../../src/app/enums/sso-provider.enum';
import {AppStateConstants, GlobalConstants} from '../../../../../../../src/app/globals';
import {SessionStorageService} from '../../../../../../../src/app/localstorage/session-storage.service';
import {StringUtils} from '../../../../../../../src/app/shared/utils/string.utils';
import {LicenseType} from '../../enums/license-type';
import {IdsTokenInfo} from '../../models/ids-token-info';
import {LsMpClassResponse} from '../../models/ls-mp-class-response';
import {AuthService} from '../../services/auth.service';
import {CleverService} from '../../services/clever.service';
import {GoogleAuthService} from '../../services/google-auth.service';
import {
  AuthActions,
  CheckTokenActions,
  ResetPasswordActions,
  ScreenSessionActions,
  SetupAccountActions,
  SetupUserSchoolActions,
  UserClassesAction,
  UserSchoolsActions,
  UserSessionActions
} from './auth.actions';
import * as AuthSelectors from './auth.selectors';
import {SessionsService} from '../../../../../../../src/app/core/sessions.service';
import {ClassResponse, EndSession, ScreenSessionResponse, StartSession, StudentResponse, TeacherResponse} from '../../../../../../../src/app/models';
import {BrowserDetectionService} from '../../../../../../../src/app/core/browser-detection.service';
import moment from 'moment/moment';
import {DeviceService} from '../../../../../../../src/app/core/device.service';
import {ENV_CONFIG, EnvironmentConfig} from '../../../environment.config';
import {UUID} from 'angular2-uuid';
import {ScreenSessionService} from '../../../../../../../src/app/api-client/screen-session.service';
import {AdminType} from '../../../../../../../src/app/enums/admin-type.enum';
import {SchoolsService} from '../../../../../../../src/app/api-client/schools.service';
import {TeacherService} from '../../../../../../../src/app/api-client/teacher.service';
import {StudentService} from '../../../../../../../src/app/api-client/student.service';
import {MpAuthInfo} from '../../models/mp-auth-info';
import {UserService} from '../../services/user.service';
import {StudioSchoolResponse} from '../../models/studio-school-response';
import {ClassLiteResponseModel} from '../../models/class-lite-response-model';
import {withNgHttpCachingContext} from '../../ng-http-caching/ng-http-caching.service';

@Injectable()
export class AuthEffects {

  authenticate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.authenticate),
      switchMap(action => {
        let context: HttpContext;
        // Only pass caching header if it has customerId, indicates that this is student
        if (action.customerId) {
          context = withNgHttpCachingContext({
            isCacheable: (req: HttpRequest<any>) => {
              return true;
            },
            getKey: (req: HttpRequest<any>) => {
              // Custom unique key to store the data
              return action.username + '@' + window.btoa(action.password) + '@' + req.method + '@' + req.urlWithParams;
            }
          });
        }
        return this.authService.authenticate(action.customerId, action.username, action.password, context).pipe(
          map(res => {
            return AuthActions.authenticateLSMP({token: res.access_token});
          }),
          catchError(err => of(AuthActions.authenticateFailure({error: err})))
        );
      })
    );
  });

  onlineAuthenticate$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.onlineAuthenticate),
    concatLatestFrom(() => [
      this.store$.select(AuthSelectors.selectMPAccessToken),
      this.store$.select(AuthSelectors.selectMpCustomerId),
      this.store$.select(AuthSelectors.selectMpUsername),
    ]),
    map(([, accessToken, customerId, username]) => {
      const accessTokenInfo = StringUtils.parseJwt(accessToken);
      return AuthActions.authenticate({customerId, username, password: accessTokenInfo['password']});
    })
  ));

  authenticateLSMP$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.authenticateLSMP),
      switchMap(action => {
        try {
          const parsedToken = StringUtils.parseJwt(action.token);
          const idsTokenInfo: IdsTokenInfo = {
            userId: parsedToken['userId'],
            username: parsedToken['username'],
            role: parsedToken['role'],
            source: parsedToken['source'],
            password: parsedToken['password'],
            licensedProducts: parsedToken['licensedProducts'] ? JSON.parse(parsedToken['licensedProducts']) : [],
            customerId: parsedToken['customerId'],
            userType: this.getUserTypeByRole(parsedToken['role']),
            adminType: this.getAdminTypeByRole(parsedToken['role']),
            isProgramManager: parsedToken['role'] === 'programmanager'
          };

          // Do not check license info for super admin
          if (idsTokenInfo.userType === UserType.MPSuperAdmin) {
            SessionStorageService.Save(GlobalConstants.IDSToken, action.token);
            return [
              AuthActions.authenticateSuccess({idsAccessToken: action.token, idsTokenInfo, lsAuthResponse: null, mpAuthResponse: null}),
              // Mark the auth workflow process completed for PLS
              AuthActions.authFlowCompleted()
            ];
            // Check for studio license
          } else if (
            idsTokenInfo.licensedProducts?.includes(LicenseType.StudioUSA) ||
            idsTokenInfo.licensedProducts?.includes(LicenseType.StudioInternational)) {
            // Proceed based on user type
            if (idsTokenInfo.userType === UserType.Student || idsTokenInfo.userType === UserType.Teacher) {
              let context: HttpContext;
              // Only pass caching header for student
              if (idsTokenInfo.userType === UserType.Student) {
                context = withNgHttpCachingContext({
                  isCacheable: (req: HttpRequest<any>) => {
                    return true;
                  },
                  getKey: (req: HttpRequest<any>) => {
                    // Custom unique key to store the data
                    return idsTokenInfo.username + '@' + req.method + '@' + req.urlWithParams;
                  }
                });
              }

              return forkJoin([
                // User username and password received from JWT token for LS authenticate if user role is student or teacher and is coming from MP temporarily
                this.authService.authenticateLS(action.token, null, null, context),
                this.authService.authenticateMP(action.token, context).pipe(
                  map(res => {
                    const parsedToken = StringUtils.parseJwt(res.access_token);
                    return <MpAuthInfo>{
                      ...res,
                      customerId: +parsedToken['customerId'],
                      userId: +parsedToken['userId'],
                      email: parsedToken['email'],
                      classIds: parsedToken['classes'] ? JSON.parse(parsedToken['classes']) : []
                    };
                  })
                )
              ]).pipe(
                switchMap(([lsAuthResponse, mpAuthResponse]) => {
                  // @TODO: Save all tokens into storage, either local or IDB for rehydration to avoid authenticate calls

                  // Store IDS token
                  SessionStorageService.Save(GlobalConstants.IDSToken, action.token);

                  // Store LS token and shard
                  SessionStorageService.Save(GlobalConstants.AuthKey, lsAuthResponse.access_token);
                  SessionStorageService.Save(GlobalConstants.ShardKey, lsAuthResponse.ShardKey);
                  // This is being used in event hub service
                  this.appStateService.set(AppStateConstants.online, true);

                  // Store MP token
                  SessionStorageService.Save(GlobalConstants.MPToken, mpAuthResponse.access_token);

                  // Google Tag Manager Reporting - START
                  window.dataLayer = window.dataLayer || [];
                  window.dataLayer.push({
                    'event': 'login',
                    'userId': idsTokenInfo.userId,
                    'accountId': mpAuthResponse.customerId,
                    'role': idsTokenInfo.role
                  });
                  // Google Tag Manager Reporting - END

                  // Here in the future when we allow more user types like district admins or partner admins
                  // who doesn't have classes then we can skip the loadUserClasses Action and call authenticateProcessCompleted() to proceed
                  if (idsTokenInfo.userType === UserType.Student) {
                    return [
                      AuthActions.authenticateSuccess({idsAccessToken: action.token, idsTokenInfo, lsAuthResponse, mpAuthResponse}),
                      SetupUserSchoolActions.setupUserSchool({schoolId: lsAuthResponse.schoolId}),
                    ];
                  } else if (
                    idsTokenInfo.adminType === AdminType.Unknown ||
                    idsTokenInfo.adminType === AdminType.SchoolAdmin ||
                    idsTokenInfo.adminType === AdminType.DistrictAdmin
                  ) {
                    return [
                      AuthActions.authenticateSuccess({idsAccessToken: action.token, idsTokenInfo, lsAuthResponse, mpAuthResponse}),
                      UserSchoolsActions.loadUserSchools(),
                    ];
                  }
                })
              );
            } else {
              return of(AuthActions.authenticateFailure({error: new HttpErrorResponse({error: 'No Login Flow defined for this User.'})}));
            }
            // If source is mindplay and user only has one of MP licenses then redirect them to MP
          } else if (idsTokenInfo.source === 'Mindplay' &&
            (
              idsTokenInfo.licensedProducts?.includes(LicenseType.UniversalScreener) ||
              idsTokenInfo.licensedProducts?.includes(LicenseType.MVRC) ||
              idsTokenInfo.licensedProducts?.includes(LicenseType.FLRTOnly) ||
              idsTokenInfo.licensedProducts?.includes(LicenseType.MindPlayLiteracy) ||
              idsTokenInfo.licensedProducts?.includes(LicenseType.Mathematics) ||
              idsTokenInfo.licensedProducts?.includes(LicenseType.DyslexiaScreener) ||
              idsTokenInfo.licensedProducts?.includes(LicenseType.MindPlayLiteracyPlus)
            )) {
            // If MP user doesn't have studio license and have MVRC or UniversalScreener license then redirect them to MP
            if (idsTokenInfo.userType === UserType.Student) {
              window.location.href = `${this.environmentConfig.mpAccountUrl}?it=${action.token}`;
            } else {
              window.location.href = `${this.environmentConfig.mpManagerUrl}?it=${action.token}`;
            }
            return of(AuthActions.authenticateSuccess({idsAccessToken: action.token, idsTokenInfo, lsAuthResponse: null, mpAuthResponse: null}));
            // If source is mindplay and user doesn't have license then stop workflow
          } else if (idsTokenInfo.source === 'Mindplay' && !idsTokenInfo.licensedProducts?.length) {
            return of(AuthActions.authenticateFailure({error: new HttpErrorResponse({error: 'No Licenses Available for this User.'})}));
            // If source is LS and user doesn't have studio license then redirect them to LS
          } else if (idsTokenInfo.source === 'LightSail' && !idsTokenInfo.licensedProducts?.includes(LicenseType.StudioUSA) &&
            !idsTokenInfo.licensedProducts?.includes(LicenseType.StudioInternational)) {
            // If LS user doesn't have studio license then redirect them to LS v10
            window.location.href = `${this.environmentConfig.lsV10Url}?it=${action.token}`;
            return of(AuthActions.authenticateSuccess({idsAccessToken: action.token, idsTokenInfo, lsAuthResponse: null, mpAuthResponse: null}));
          } else {
            return of(AuthActions.authenticateFailure({error: new HttpErrorResponse({error: 'This Licenses type is not supported in app.'})}));
          }
        } catch (e) {
          throw e;
        }
      }),
      catchError((err, caught) => merge(caught, of(AuthActions.authenticateFailure({error: err}))))
    );
  });

  authenticateSSO$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.authenticateSSO),
      switchMap(action => {
        switch (action.ssoProvider) {
          case SsoProvider.Google:
            return from(this.googleAuthService.authenticate()).pipe(
              map(res => ({token: res.access_token, ssoProvider: action.ssoProvider}))
            );
          case SsoProvider.ActiveDirectory:
            return from(this.msalService.login()).pipe(
              map(res => ({token: res.token, ssoProvider: action.ssoProvider}))
            );
          case SsoProvider.ClassLink:
            return this.classLinkService.authenticate().pipe(
              map(token => ({token: token, ssoProvider: action.ssoProvider}))
            );
          case SsoProvider.Clever:
            if (action.token) {
              return this.cleverService.exchangeToken(action.token).pipe(
                map(token => ({token: token, ssoProvider: action.ssoProvider}))
              );
            } else {
              return this.cleverService.authenticate().pipe(
                map(token => ({token: token, ssoProvider: action.ssoProvider}))
              );
            }
          case SsoProvider.GlobalGridForLearning:
            return from(this.gg4lAuthService.authenticate()).pipe(
              map(token => ({token: token, ssoProvider: action.ssoProvider}))
            );
        }
      }),
      switchMap(({token, ssoProvider}) => {
        return this.authService.authenticateViaSSO(token, ssoProvider).pipe(
          map(res => {
            return AuthActions.authenticateLSMP({token: res.access_token});
          }),
        );
      }),
      catchError((err, caught) => merge(caught, of(AuthActions.authenticateFailure({error: err}))))
    );
  });

  logoutMPManagerUser$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.logoutMPManagerUser),
    concatLatestFrom(() => this.store$.select(AuthSelectors.selectMPAccessToken)),
    switchMap(([action, token]) => {
      return this.authService.logoutMPManagerUser(token)
        .pipe(map(() => AuthActions.logoutMPManagerUserSuccess()))
    }),
    catchError((err, caught) => merge(caught, of(AuthActions.logoutMPManagerUserFailure({error: err}))))
  ));

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.logout),
    map((action) => {
      return UserSessionActions.endUserSession({logout: true});
    })
  ));

  loadUserSchools$ = createEffect(() => this.actions$.pipe(
    ofType(UserSchoolsActions.loadUserSchools),
    concatLatestFrom(action => [
      this.store$.select(AuthSelectors.selectDefaultSchoolId),
      this.store$.select(AuthSelectors.selectUserId),
      this.store$.select(AuthSelectors.selectMpCustomerId)
    ]),
    switchMap(([action, defaultSchoolId, userId, mpCustomerId]) =>
      forkJoin([
        this.schoolsService.getSchoolsByUserId(userId, true, CachePriority.NoCache),
        this.userService.getSchoolsLiteByCustomerId(mpCustomerId)
      ]).pipe(
        switchMap(([schools, mpSchoolsLite]) => {

          const mpSchoolsMap = mpSchoolsLite.reduce((acc, item) => ({...acc, [item.schoolName?.trim()?.toLowerCase()]: item.schoolId}), {});
          // Populate Mp schoolId in school response
          const studioSchools: StudioSchoolResponse[] = schools.map(sch => ({...sch, mpSchoolId: mpSchoolsMap[sch.userGroupOrganizationName?.trim()?.toLowerCase()] ?? null}));

          const schoolsWithClasses = studioSchools.filter(sch => sch.hasActiveClasses);
          // Check if there is already a schoolId in session storage
          // If there is then its a reload procedure, in that case preselect that school otherwise select first school
          const selectedSchoolId = SessionStorageService.Get(GlobalConstants.SchoolId);
          const selectedSchool = selectedSchoolId ?
            schoolsWithClasses.find(sch => sch.schoolId === selectedSchoolId) :
            schoolsWithClasses.find(sch => sch.schoolId === defaultSchoolId);

          const school = selectedSchool ?? schoolsWithClasses?.[0] ?? studioSchools?.[0];
          return [
            UserSchoolsActions.loadUserSchoolsSuccess({schools: studioSchools}),
            SetupUserSchoolActions.setupUserSchool({schoolId: school.schoolId})
          ];
        }),
        catchError(error => of(UserSchoolsActions.loadUserSchoolsFailure({error})))
      )
    )
  ));

  setupUserSchool$ = createEffect(() => this.actions$.pipe(
    ofType(SetupUserSchoolActions.setupUserSchool),
    concatLatestFrom(action => [
      this.store$.select(AuthSelectors.selectUserType),
      this.store$.select(AuthSelectors.selectAdminType),
      this.store$.select(AuthSelectors.selectMpUsername),
      this.store$.select(AuthSelectors.selectStudentId),
      this.store$.select(AuthSelectors.selectTeacherId),
      this.store$.select(AuthSelectors.selectUserSchoolForSchoolId(action.schoolId))
    ]),
    switchMap(([action, userType, adminType, mpUsername, studentId, teacherId, selectedSchool]) => {
      // Storing schoolId in session storage so it can be recovered in case of browser refresh
      SessionStorageService.Save(GlobalConstants.SchoolId, action.schoolId);

      let context: HttpContext;
      // Only pass caching header for student
      if (userType === UserType.Student) {
        context = withNgHttpCachingContext({
          isCacheable: (req: HttpRequest<any>) => {
            return true;
          },
          getKey: (req: HttpRequest<any>) => {
            // Custom unique key to store the data
            return mpUsername + '@' + req.method + '@' + req.urlWithParams;
          }
        });
      }

      return forkJoin([
        // mpSchoolId is not available for student, in the future, that can be added to mp auth token for student if needed
        selectedSchool ?
          of(selectedSchool) :
          this.schoolsService.Get(action.schoolId, CachePriority.NoCache, context).pipe(map(res => ({...res, mpSchoolId: null}))),
        this.schoolsService.GetSchoolMetaData(action.schoolId, adminType === AdminType.DistrictAdmin, CachePriority.NoCache, context)
      ]).pipe(
        switchMap(([school, metaData]) => {
          const id = userType === UserType.Student ? studentId : teacherId;

          return [
            SetupUserSchoolActions.setupUserSchoolSuccess({school, metaData}),
            UserClassesAction.loadUserClasses({id, userType, adminType, schoolId: action.schoolId, mpSchoolId: school.mpSchoolId})
          ];
        }),
        catchError(error => of(SetupUserSchoolActions.setupUserSchoolFailure({error})))
      );
    })
  ));

  loadUserClasses$ = createEffect(() => this.actions$.pipe(
    ofType(UserClassesAction.loadUserClasses),
    concatLatestFrom(() => [
      this.store$.select(AuthSelectors.selectMpClassIds),
      this.store$.select(AuthSelectors.selectMpUsername)
    ]),
    switchMap(([action, mpClassIds, mpUsername]) => {
      let observable: Observable<[ClassResponse[], ClassLiteResponseModel[]]>;
      if (action.userType === UserType.Student) {
        const context = withNgHttpCachingContext({
          isCacheable: (req: HttpRequest<any>) => {
            return true;
          },
          getKey: (req: HttpRequest<any>) => {
            // Custom unique key to store the data
            return mpUsername + '@' + req.method + '@' + req.urlWithParams;
          }
        });

        observable = forkJoin([
          this.classesService.GetClassesByStudent(action.id, CachePriority.NoCache, context),
          mpClassIds?.length ? this.userService.getClassesByClassIds(mpClassIds, context) : of([])
        ]);
      } else {
        observable = forkJoin([
          this.schoolsService.GetClassesInSchool(action.schoolId, action.adminType === AdminType.DistrictAdmin, CachePriority.NoCache),
          action.mpSchoolId ? this.userService.getClassesBySchoolIds([action.mpSchoolId]) : of([])
        ]);
      }

      return observable.pipe(
        map(([classes, mpClasses]) => {
          if (!classes?.length) {
            throw new HttpErrorResponse({error: 'No active classes in selected school.'});
          }

          const mpClassesMap = mpClasses.reduce((acc, item) => ({...acc, [item.className?.trim()?.toLowerCase()]: item.classId}), {});

          return classes
            .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
            .map<LsMpClassResponse>((cls) => {
              return {
                ...cls,
                mpIdAtSource: mpClassesMap[cls.name?.trim()?.toLowerCase()] || null,
              };
            });
        }),
        switchMap(classes => {
          const classIds = classes.map(cls => cls.classId);
          return forkJoin({
            classes: of(classes),
            classStudentsMap: action.userType === UserType.Teacher ?
              this.studentService.getStudentsBySchoolAndClasses(action.schoolId, classIds).pipe(map(students => {
                let classStudentsMap: Record<string, StudentResponse[]> = {};
                students.map(({classIds, ...student}) => {
                  student.schoolId = action.schoolId;
                  student.fullName = `${student.firstName} ${student.lastName}`;
                  classIds.map(classId => {
                    classStudentsMap = {...classStudentsMap, [classId]: [...(classStudentsMap[classId] || []), student]};
                  });
                });
                return classStudentsMap;
              })) :
              of(null),
            classTeachersMap: action.userType === UserType.Teacher ?
              this.teacherService.getTeachersBySchoolAndClasses(action.schoolId, classIds).pipe(map(teachers => {
                let classTeachersMap: Record<string, TeacherResponse[]> = {};
                teachers.map(({classIds, ...teacher}) => {
                  teacher.fullName = `${teacher.firstName} ${teacher.lastName}`;
                  classIds.map(classId => {
                    classTeachersMap = {...classTeachersMap, [classId]: [...(classTeachersMap[classId] || []), teacher]};
                  });
                });
                return classTeachersMap;
              })) :
              of(null)
          });
        })
      );
    }),
    switchMap((userClassesData) => {
      // Check if there is already a classId in session storage
      // If there is then its a reload procedure, in that case preselect that class and mark the flow as complete
      const selectedClassId = SessionStorageService.Get(GlobalConstants.ClassId);
      if (selectedClassId) {
        return [
          UserClassesAction.loadUserClassesSuccess({userClassesData}),
          UserClassesAction.selectUserClass({selectedClass: userClassesData.classes.find(cls => cls.classId === selectedClassId) ?? userClassesData.classes[0]}),
          AuthActions.authFlowCompleted(),
        ];
      } else {
        return [
          UserClassesAction.loadUserClassesSuccess({userClassesData}),
        ];
      }
    }),
    catchError((error, caught) => merge(caught, of(UserClassesAction.loadUserClassesFailure({error}))))
  ));

  selectUserClass$ = createEffect(() => this.actions$.pipe(
    ofType(UserClassesAction.selectUserClass),
    switchMap(action => {
      // Storing classId in session storage so it can be recovered in case of browser refresh
      SessionStorageService.Save(GlobalConstants.ClassId, action.selectedClass.classId);

      return [
        // End Previous Login Session
        UserSessionActions.endUserSession({logout: false}),
        // Start New Login Session
        UserSessionActions.startUserSession({userGroupId: action.selectedClass.userGroupId})
      ];
    })
  ));

  startUserSession$ = createEffect(() => this.actions$.pipe(
    ofType(UserSessionActions.startUserSession),
    concatLatestFrom(() => [
      this.store$.select(AuthSelectors.selectUserId),
      this.store$.select(AuthSelectors.selectUTCOffset),
    ]),
    switchMap(([action, userId, utcOffset]) => {
      const now = moment().utcOffset(utcOffset, false); //get the utcOffset fixed date
      const startSession: StartSession = {
        isOffline: false,
        offlineSessionId: UUID.UUID(),
        userId: userId,
        userGroupId: action.userGroupId,
        startTime: now.toISOString(),
        deviceType: this.deviceService.getDeviceType(),
        deviceVersion: JSON.stringify({
          'platform': BrowserDetectionService.getApplicationPlatform(),
          'browserVersion': BrowserDetectionService.getBrowserVersion()
        }),
        applicationPlatform: GlobalConstants.ApplicationPlatform,
        applicationBuildType: GlobalConstants.ApplicationBuildType,
        lightSailVersion: this.environmentConfig.version,
        isOutsideSchoolHours: false, //TODO: remove hardcoded value when outside school hour feature implement
        isDaylightSaving: false, //TODO: remove hardcoded value when outside school hour feature implement
        utcOffset: utcOffset ? utcOffset : 0,
        localTime: now.format('HH:mm:ss')
      };
      return this.sessionsService.postStartSession(userId, startSession, true).pipe(
        map(res => {
          // Store sessionId in session storage in case user browser reloads then this can be used to close previous session
          SessionStorageService.Save(GlobalConstants.UserSessionId, res.objectId);

          return UserSessionActions.startUserSessionSuccess({sessionId: res.objectId});
        }),
        catchError(error => of(UserSessionActions.startUserSessionFailure({error})))
      );
    })
  ));

  endUserSession$ = createEffect(() => this.actions$.pipe(
    ofType(UserSessionActions.endUserSession),
    concatLatestFrom(() => [
      this.store$.select(AuthSelectors.selectUserId),
      this.store$.select(AuthSelectors.selectUTCOffset),
      this.store$.select(AuthSelectors.selectUserSessionId),
    ]),
    switchMap(([action, userId, utcOffset, sessionId]) => {
      let now = moment().utcOffset(utcOffset || 0, false); //get the utcOffset fixed date
      const endSession: EndSession = {
        userId,
        endTime: now.toISOString(),
        sessionId: sessionId,
        offlineSessionId: sessionId,
        mustLogout: action.logout,
        isOffline: false
      };

      const observable = sessionId ? this.sessionsService.postEndSession(userId, endSession, true) : of({});
      return observable.pipe(
        map(res => {
          return UserSessionActions.endUserSessionSuccess();
        }),
        catchError(error => {
          return of(UserSessionActions.endUserSessionFailure({error}));
        }),
        finalize(() => {
          if (action.logout) {
            window.sessionStorage.clear();
            // This is being used in event hub service
            this.appStateService.set(AppStateConstants.online, false);
            // If this is triggered by logout then call logout success which will clear out the ngrx state in meta reducer
            this.store$.dispatch(AuthActions.logoutSuccess());
          }
        })
      );
    })
  ));

  logScreenSession$ = createEffect(() => this.actions$.pipe(
    ofType(ScreenSessionActions.logScreenSession),
    concatLatestFrom(() => [
      this.store$.select(AuthSelectors.selectUserId),
      this.store$.select(AuthSelectors.selectCurrentUserGroupId),
      this.store$.select(AuthSelectors.selectUTCOffset),
      this.store$.select(AuthSelectors.selectScreenSession)
    ]),
    switchMap(([action, userId, userGroupId, utcOffset, screenSession]) => {
      const startTime = moment().utcOffset(utcOffset, false); //get the utcOffset fixed date
      const inactivityTimeoutInSeconds = 300;       // @TODO: Replace with actual inactivity timeout from system conf.
      const expirationTime = moment().add(inactivityTimeoutInSeconds, 's');

      const postData: ScreenSessionResponse = {
        userGroupId: userGroupId,
        screenType: action.screenType,
        startTime: moment(startTime).utc(),
        expirationTime: expirationTime.utc(),
      };

      if (screenSession?.screenType === action.screenType) {
        return from(this.screenSessionService.updateScreenSession(userId, screenSession.screenSessionId, postData.expirationTime)).pipe(
          map(res => ScreenSessionActions.logScreenSessionSuccess({data: {...postData, screenSessionId: res.objectId}})),
          catchError(error => of(ScreenSessionActions.logScreenSessionFailure({error})))
        );
      } else {
        return from(this.screenSessionService.postScreenSession(userId, postData)).pipe(
          map(res => ScreenSessionActions.logScreenSessionSuccess({data: {...postData, screenSessionId: res.objectId}})),
          catchError(error => of(ScreenSessionActions.logScreenSessionFailure({error})))
        );
      }
    })
  ));

  checkTokenValidation$ = createEffect(() => this.actions$.pipe(
    ofType(CheckTokenActions.checkTokenValidation),
    switchMap(action => this.authService.getUserInfo(action.token).pipe(
      map((data) => {
        // Store MP token to perform async validations
        SessionStorageService.Save(GlobalConstants.MPToken, action.token);

        return CheckTokenActions.checkTokenValidationSuccess({user: data});
      }),
      catchError(error => of(CheckTokenActions.checkTokenValidationFailure({error})))
    ))
  ));

  resetPassword$ = createEffect(() => this.actions$.pipe(
    ofType(ResetPasswordActions.resetPassword),
    concatLatestFrom(() => this.store$.select(AuthSelectors.selectTokenValidatedUser)),
    switchMap(([action, user]) => this.authService.resetPasswordWithToken(user.userId, action.newPassword, action.token).pipe(
      map(() => ResetPasswordActions.resetPasswordSuccess()),
      catchError(error => of(ResetPasswordActions.resetPasswordFailure({error})))
    ))
  ));

  setupAccount$ = createEffect(() => this.actions$.pipe(
    ofType(SetupAccountActions.setupAccount),
    concatLatestFrom(() => this.store$.select(AuthSelectors.selectTokenValidatedUser)),
    switchMap(([action, user]) =>
      forkJoin([
        this.authService.confirmUserAccountWithToken(user.userId, action.request, action.token),
        this.authService.postEulaAccepted(user.userId, action.token)
      ]).pipe(
        map(() => SetupAccountActions.setupAccountSuccess()),
        catchError(error => of(SetupAccountActions.setupAccountFailure({error})))
      ))
  ));

  resendSetupAccount$ = createEffect(() => this.actions$.pipe(
    ofType(SetupAccountActions.resendSetupAccount),
    concatLatestFrom(() => this.store$.select(AuthSelectors.selectTokenValidatedUser)),
    switchMap(([action, user]) => this.authService.sendConfirmation(action.email).pipe(
      map(() => SetupAccountActions.resendSetupAccountSuccess()),
      catchError(error => of(SetupAccountActions.resendSetupAccountFailure({error})))
    ))
  ));

  getUserTypeByRole(role: string) {
    if (role === 'superadmin') {
      return UserType.MPSuperAdmin;
    } else if (role === 'teacher' ||
      role === 'administrator' ||
      role === 'districtadministrator' ||
      role === 'programmanager') {
      return UserType.Teacher;
    } else {
      return UserType.Student;
    }
  }

  getAdminTypeByRole(role: string) {
    if (role === 'administrator') {
      return AdminType.SchoolAdmin;
    }
    if (role === 'districtadministrator') {
      return AdminType.DistrictAdmin;
    }
    if (role === 'programmanager') {
      return AdminType.DistrictAdmin;
    }
    return AdminType.Unknown;
  }

  constructor(private actions$: Actions,
              private store$: Store,
              private authService: AuthService,
              private msalService: MsalService,
              private classLinkService: ClasslinkService,
              private cleverService: CleverService,
              private gg4lAuthService: Gg4lAuthService,
              private googleAuthService: GoogleAuthService,
              private classesService: ClassesService,
              private schoolsService: SchoolsService,
              private studentService: StudentService,
              private teacherService: TeacherService,
              private appStateService: AppStateService,
              private sessionsService: SessionsService,
              private deviceService: DeviceService,
              @Inject(ENV_CONFIG) private environmentConfig: EnvironmentConfig,
              private screenSessionService: ScreenSessionService,
              private userService: UserService
  ) {
  }
}
